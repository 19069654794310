import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate  } from 'react-router-dom';
import './search.css';


function SearchBar() {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [categoryResults, setCategoryResults] = useState([]);
    const searchContainerRef = useRef(null);
    const [products, setProducts] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const navigate = useNavigate();
    const API_SERVER = process.env.REACT_APP_API_SERVER;


    const handleSearchChange = event => {
        const searchTermValue = event.target.value;
        setSearchTerm(searchTermValue);
    
        if (products.item && products.item.length !== 0) {
            setSearchResults(products.item);
            
        } else {
            setSearchResults([]);            
        }
    
        if (products.menu && products.menu.length !== 0) {
            setCategoryResults(products.menu);	
            
        } else {
            setCategoryResults([]);
        }

        
    };

    useEffect(() => {
        if (searchTerm === '') {
            setSearchResults([]);
            setCategoryResults([]);
            setIsActive(false);
            
        } else {
        fetch(`${API_SERVER}/search?search=${searchTerm}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Failed to fetch user data");
                    }
                    return response.json();
                })
                .then((data) => {
                    setProducts(data);
                    setIsActive(true);
                    

                })
                .catch((error) => console.error(error));
        }
    }, [searchTerm]);

    useEffect(() => {
        const handleClickOutside = event => {
            if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
                setSearchResults([]);
                setCategoryResults([]);
                setIsActive(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleLinkClick = () => {
        setSearchResults([]);
        setCategoryResults([]);
        setSearchTerm('');
        setIsActive(false);
    };
    const handleShowFullResults = () => {
        const encodedResults = encodeURIComponent(JSON.stringify(searchTerm));
        navigate(`/search-results?data=${encodedResults}`);
        setIsActive(false);
        setSearchResults([]);
        setSearchTerm('');
      };

   
    return (
        <>
        {isActive ? <div className="Cover-container"> </div>: null}
        <div className="search-container" ref={searchContainerRef}>
            <input
                type="text"
                placeholder="Atrodi savu no vairāk kā 50`000+ precēm"
                value={searchTerm}
                onChange={handleSearchChange}
                onKeyDown={(e) => e.key === 'Enter' && handleShowFullResults()}
            />
            <ul className="search-results">
                {categoryResults.length > 0 && 
                <p className='search-headers'>Meklēšanas rezultāti no kategorijām</p>}
                {categoryResults.map(item => (
                    <Link
                        to={`/categories/${encodeURIComponent(item.MENU_1).replace(/%20/g,'-')}/${encodeURIComponent(item.MENU_2).replace(/%20/g,'-')}`}                     
                        key={item.id}
                        onClick={handleLinkClick}
                        className='search-link'
                    >
                        <li className='category' key={item.id}>
                            <img src={`/catImages/${item.MENU_2}.jpg`} alt={item.MENU_2} />
                            <p>{item.MENU_2}</p>
                        </li>
                        
                    </Link>
                ))}
                {searchResults.length > 0 && <p className='search-headers'>Meklēšanas rezultāti no produktiem</p>}
                {searchResults.map(item => (
                    <Link
                        to={
                            item.MENU_3 === "N/A"
                                ? `/categories/${encodeURIComponent(item.MENU_1).replace(/%20/g,'-')}/${encodeURIComponent(item.MENU_2).replace(/%20/g,'-')}/${item.SKU}`
                                : `/categories/${encodeURIComponent(item.MENU_1).replace(/%20/g,'-')}/${encodeURIComponent(item.MENU_2).replace(/%20/g,'-')}/${encodeURIComponent(item.MENU_3).replace(/%20/g,'-')}/${item.SKU}`
                        }
                        key={item.id}
                        onClick={handleLinkClick}
                        className='search-link'
                    >
                        <li key={item.id}>
                            <img 
                            src={item.MENU_1 === "Officeshop ražotie kārtridži" ? '/prodImages/Small/Officeshop Cartridges (Small)/cartridges (Small).jpg' :
                            item.MENU_1 === "Kopējošās lentas (ribbon)" ? '/prodImages/Small/Ribbon (Small)/ribbon (Small).jpg' :
                            item.PICTURE ? item.PICTURE.startsWith('http') ? item.PICTURE.replace('ProductImages', 'ProductImagesThumbs'): `/prodImages/Small/${item.SKU} (Small)/${item.SKU} (Small).${item.PICTURE}` :
                                        `/prodImages/Small/logo (Small)/logo (Small).png`}
                            alt={item.NAME} />
                            <p>{item.NAME}</p>
                            <h4 className='price'>€ {item.PRICE}</h4>
                        </li>
                    </Link>
                ))}
                {searchResults.length > 0 &&  
                <div
                    className='search-button'
                    onClick={handleShowFullResults}
                >
                    Rādīt visus rezultātus
                </div>}
            </ul>
        </div>
        </>
    );
}

export default SearchBar;
