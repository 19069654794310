import React from "react";
import { RiPrinterLine } from "react-icons/ri";
import './Drop.css'


export function Kartridzi() {
    return (
        <div className="main-container">
            <div className="virsraksts" onClick={() => {window.location.href = "/categories/Officeshop-ražotie-kārtridži/"; }}>
                <RiPrinterLine
                    className="thirdRowIcon"
                />
                <div>Printeru kārtridži</div>
            </div>
            
        </div>
    );
}