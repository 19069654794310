// ProductCard.js
import React from 'react';
import { addToCart } from '..//..//context/cartFunctions'
import { MdFavoriteBorder } from "react-icons/md";
import { AiFillHeart } from "react-icons/ai";
import useFirebase from "../../firebase/usefirebase";
import { onAuthStateChanged } from "firebase/auth";
import './ProductCard.css'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useNotification  from "../Notifications/notificationHandler"
import { TbShoppingCartPlus } from "react-icons/tb";
import { getCart } from '..//..//context/cartFunctions';
import { TbTruckDelivery } from "react-icons/tb";
import { RiDiscountPercentFill } from "react-icons/ri";
import { FaCheckCircle } from "react-icons/fa";
 



const ProductCard = ({ name, price, quantity, image, link, sku, onDeleteFav, fav, minorder, discount }) => {
  const navigate = useNavigate();  
  const [pcsToCart, setPcsToCart] = useState(minorder);
  const [email, setEmail] = useState("");
  const [showFreeDelivery, setShowFreeDelivery] = useState(false);
  const [notAuhfavClick, setNotAuhfavClick] = useState(false);
  const showNotification = useNotification();
  const { auth, user } = useFirebase();
  const API_SERVER = process.env.REACT_APP_API_SERVER;
  
 

  useEffect(() => {
    if (auth) {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setEmail(user.email);
      }
    });
    return () => {
      unsubscribe();
    };
  }
  }, [auth]);

 
  const handleAddToCart = async () => {
    setShowFreeDelivery(false);
    const cartOld = getCart();
    addToCart(sku, parseInt(pcsToCart, 10));
    
    const serializedCartOld = encodeURIComponent(JSON.stringify(cartOld));
    const responseOld = await fetch(`${API_SERVER}/checkout?cart=${serializedCartOld}`);
    if (!responseOld.ok) {
      throw new Error("Failed to fetch cart data");
    }
    const resOld = await responseOld.json();
    const deliveryOld = resOld.delivery;
    showNotification('Prece pievienota grozam!');
    
    const cart = getCart();
    
    const serializedCart = encodeURIComponent(JSON.stringify(cart));
    const response = await fetch(`${API_SERVER}/checkout?cart=${serializedCart}`);
    if (!response.ok) {
      throw new Error("Failed to fetch cart data");
    }
    const res = await response.json();
    const delivery = res.delivery;
    const total = res.total;
    
    if (deliveryOld.length !==0 && delivery.length === 0) {
      setShowFreeDelivery(true);
    } 
  };

 

  const handleIconClick = async (event) => {
    if (email === "") {
      dispatcNotification();
    } else {
    try {
      const response = await fetch(`${API_SERVER}/addFavorite`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          Email: email,
          Favorite: sku,
        })
      });
      if (response.ok && onDeleteFav) {
        onDeleteFav(); 
      }
    } catch (error) {
      console.log(error);
    }
    event.stopPropagation();
  }
  
  };
  const dispatcNotification = () => {
    setNotAuhfavClick(true);
  }

  const handleImgClick = () => {
    navigate(link);
  };

  
  const handleScrooToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    
};
  
  
  return (
    <>
      <div className='product-card'>
        {discount !== null && <div className="discount-price">€{price}</div>}
        <div className='image-container' onClick={handleScrooToTop}>
          <img
            src={image}
            alt={name}
            className="product-image"
            onClick={handleImgClick} />
          {fav !== 'yes' ?
            <MdFavoriteBorder className="icon-blank" onClick={handleIconClick} /> :
            <AiFillHeart style={{ color: "#f70d1a" }} className="icon" onClick={handleIconClick} />}
          {discount !== null ? (              
              <div className='discountContainer'>
              <RiDiscountPercentFill className='discounticon'/>
              <p className='discountValue'>-{((discount-1)*100).toFixed(0)}%</p>              
              </div>                          
          ) : null}
          {quantity <5 && quantity >0 && (
            <div className="lastChanceContainer">
              <img src='/images/lastChance.png' title={quantity === 1 ?  "Pēdējā prece noliktavā": `Pēdējās ${quantity} preces noliktavā`}/>
            </div>
          )}
        </div>
        {/* {quantity > 0 && (
          <div className="ribbon">
            <span className="ribbon3">
              <p>Pieejams noliktavā</p>
            </span>
          </div>
        )} */}
        { quantity > 0 && (
          <div className='inStockContainer'>
          <FaCheckCircle className='instockIcon'/>                     
          <p className='inStockText'><FaCheckCircle className='instockIconHover'/> Pieejams noliktavā</p>
          </div>    
        )}

        <div className="product-name">{name}</div>
        {discount !== null ? 
        <div className="product-price" style={{textDecoration:"line-through", fontWeight:"500", marginTop:"19px"}}>€{ (price * discount).toFixed(2)}</div>
        : <div className="product-price">€{price}</div>}
        {discount === null && <div className="product-price_noVAT">bez PVN €{(price / 1.21).toFixed(2)}</div>}
        <div className="buttonWrapper">
          <button className="addToCart" onClick={handleAddToCart}>
            <input 
              onChange={(e) => setPcsToCart(e.target.value)}
              onClick={(e) => e.stopPropagation()}
              type="number"
              value={pcsToCart}
              min={minorder}
              max="30"
            />
            <div className='addToCartIconContainer'>
            <TbShoppingCartPlus className="addToCartIcon" />
            </div>
          </button>
        </div>
      </div>
      <div>
        {notAuhfavClick ? (
          <div className="Cover-container">
            <div className="logs">
              <h2>Favorītus var pievienot reģistrēti lietotāji</h2>
              <p>Aicinam reģistrēties vai pieslēgties savam kontam</p>
              <button className='logIn' onClick={() => setNotAuhfavClick(false)} >Ok</button>
            </div>
          </div>
        ) : null}
      </div>
      {showFreeDelivery ? (
                <div className='FreeDeliveryNotificationMobile'>
                        <p>Jūsu pasūtījumam bezmaksas piegāde!
                        <TbTruckDelivery className='delivery'/>
                        </p>
                      </div>
                ) : null}
        
      
    </>
  );
};

export default ProductCard;




