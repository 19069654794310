import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { GrClose } from "react-icons/gr";
import { MdOutlineScreenSearchDesktop } from "react-icons/md";
import './cartridgeSearch.css';

function CartridgeSearch() {
    const [searchResults, setSearchResults] = useState([]);
    const searchContainerRef = useRef(null);
    const [allBrands, setAllBrands] = useState([]);
    const [brand, setBrand] = useState([]);
    const [printerList, setPrinterList] = useState(null);
    const [selectedPrinter, setSelectedPrinter] = useState([]);
    const [searchproductSKU, setSearchproductSKU] = useState([]);
    const [userSearch, setUserSearch] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const [brandIsActive, setBrandIsActive] = useState(false);
    const [modelIsActive, setModelIsActive] = useState(false);
    const API_SERVER = process.env.REACT_APP_API_SERVER;

  

    const toggleFunction = () => {
        setIsActive(!isActive);
        setBrandIsActive(false);
        setModelIsActive(false);
        setSelectedPrinter([]);
        setBrand([]);
        //setSearchResults([]);
        // document.getElementById('myInput').value = '': null;
    };

    const brandActive = () => {
        setBrandIsActive(!brandIsActive);
    };
    const modelActive = () => {
        setModelIsActive(!modelIsActive);
    };

    const handleSearch = (e) => {
        const searchValue = e.target.value;
        const filteredPrinters = printerList.filter((printer) =>
            printer.toLowerCase().includes(searchValue.toLowerCase())
        );
        setUserSearch(filteredPrinters);
    };
    
    
    

    useEffect(() => {
        if (selectedPrinter?.length > 0) {
            fetch(`${API_SERVER}/cartridgeSearch?Model=${selectedPrinter}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Failed to fetch user data");
                    }
                    return response.json();
                })
                .then((data) => {
                    setSearchproductSKU(data.map(item => item.SKU));
                })
                .catch((error) => console.error(error));
        }

    }, [selectedPrinter]);

    useEffect(() => {
        fetch(`${API_SERVER}/sku?skus=${searchproductSKU}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Failed to fetch user data");
                    }
                    return response.json();
                })
                .then((data) => {
                    setSearchResults(data);
                })
                .catch((error) => console.error(error));
    }, [searchproductSKU]);

    
    useEffect(() => {
        if (isActive) {
        fetch(`${API_SERVER}/cartridgeSearch?Brand=all`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Failed to fetch user data");
                    }
                    return response.json();
                })
                .then((data) => {
                    setAllBrands(data.map(item => item.BRAND));
                    
                })
                .catch((error) => console.error(error));
        }
    }, [isActive]);

    useEffect(() => {
        if (brand?.length > 0) {
            fetch(`${API_SERVER}/cartridgeSearch?Brand=${brand}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Failed to fetch user data");
                    }
                    return response.json();
                })
                .then((data) => {
                    setPrinterList(data.map(item => item.MODEL));
                    setSelectedPrinter([]);
                    setSearchResults([]);
                    setUserSearch(null);
                    document.getElementById('myInput').value = '';
                })
                .catch((error) => console.error(error));
        }
    }, [brand]);

    

    

    
   
    return (    
        <>
            <div onClick={toggleFunction} className="main-container">
                <div className="virsraksts">
                    <MdOutlineScreenSearchDesktop
                        className='thirdRowIcon'
                    />
                    <div>Kārtridžu meklētājs</div>
                </div>
            </div>
            {isActive ? <div className="Cover-container"> </div>: null}
            <div className={`slide-in ${isActive ? 'active' : ''}`}>
                <div className='virsraksts-cartridge'>
                    <h2>Kārtridžu meklētājs</h2>
                    <GrClose onClick={toggleFunction}  className='icon'>Aizvērt</GrClose>
                </div>
                <div className="search-container-cartridge" ref={searchContainerRef}>
                <div className='izvelne' onClick={brandActive}>{brand?.length > 0 ? brand : "Izvēlieties printera ražotāju"}</div>
                    {allBrands.map((brand) => (
                        <div
                            value={brand}
                            className={`saraksts ${brandIsActive ? 'active' : ''}`}
                            onClick={(e) => {
                                setBrand(e.target.getAttribute('value'));
                                brandActive();
                            }}
                        >
                            {brand}
                        </div>
                    ))}
                     {printerList ? (
                        <>
                            <div className='izvelne' onClick={modelActive}> {selectedPrinter?.length > 0 ? selectedPrinter: "Izvēlieties modeli"}</div>
                             <div className='saraksta-konteiners'>
                            <input type="text" id='myInput' className={`mekletajs ${modelIsActive ? 'active' : ''}`} placeholder={"Meklēt printeri no saraksta"} onChange={handleSearch}/>
                             {userSearch ? (
                             userSearch.map((brand) => (
                                <div
                                    value={brand}
                                    className={`saraksts ${modelIsActive ? 'active' : ''}`}
                                    onClick={(e) => {
                                        setSelectedPrinter(e.target.getAttribute('value'));
                                        modelActive();
                                    }}
                                >
                                    {brand}
                                </div>
                            ))):
                            printerList.map((brand) => (
                                <div
                                    value={brand}
                                    className={`saraksts ${modelIsActive ? 'active' : ''}`}
                                    onClick={(e) => {
                                        setSelectedPrinter(e.target.getAttribute('value'));
                                        modelActive();
                                    }}
                                >
                                    {brand}
                                </div>
                            ))
                        }
                            </div>

                        </>
                        ) : null}
                  
                    {searchResults.length > 0 && (
                        <div>
                            {Array.from(new Set(searchResults.map((item) => item.MENU_1)))
                                .sort((a, b) => a.localeCompare(b))
                                .map((menu1) => (
                                    <div key={menu1}>
                                        <p className="search-headers-cartridges">{menu1}</p>
                                        <ul className="search-results-cartridges">
                                            {searchResults
                                                .filter((item) => item.MENU_1 === menu1)
                                                .map((item) => (
                                                    <Link
                                                        to={
                                                            item.MENU_3 === 'N/A'
                                                                ? `/categories/${encodeURIComponent(item.MENU_1).replace(
                                                                      /%20/g,
                                                                      '-'
                                                                  )}/${encodeURIComponent(item.MENU_2).replace(
                                                                      /%20/g,
                                                                      '-'
                                                                  )}/${item.SKU}`
                                                                : `/categories/${encodeURIComponent(item.MENU_1).replace(
                                                                      /%20/g,
                                                                      '-'
                                                                  )}/${encodeURIComponent(item.MENU_2).replace(
                                                                      /%20/g,
                                                                      '-'
                                                                  )}/${encodeURIComponent(item.MENU_3).replace(
                                                                      /%20/g,
                                                                      '-'
                                                                  )}/${item.SKU}`
                                                        }
                                                        key={item.id}
                                                        className="search-link"
                                                    >
                                                        <li key={item.id}>
                                                            <img
                                                                src={item.MENU_1 === "Officeshop ražotie kārtridži" ? '/prodImages/Small/Officeshop Cartridges (Small)/cartridges (Small).jpg' :
                                                                    item.MENU_1 === "Kopējošās lentas (ribbon)" ? '/prodImages/Small/Ribbon (Small)/ribbon (Small).jpg' :
                                                                        item.PICTURE ? `/prodImages/Small/${item.SKU} (Small)/${item.SKU} (Small).${item.PICTURE}` :
                                                                            `/prodImages/Small/logo (Small)/logo (Small).png`}
                                                                alt={item.NAME} />
                                                            <p>{item.NAME}</p>
                                                            <h4 className="price">€ {item.PRICE}</h4>
                                                        </li>
                                                    </Link>
                                                ))}
                                        </ul>
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            
            </div>
        </>
    );
}

export default CartridgeSearch;
