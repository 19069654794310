import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import '../components/ProductDisplay/categories.css';
import useFirebase from "../firebase/usefirebase";
import { onAuthStateChanged } from "firebase/auth";
import { Helmet } from 'react-helmet';

import Mobile from '../components/Reklama/Sides/Mobile.js';





const BackToSchool = () => {
  const { menu1, menu2, menu3, product } = useParams();
  
  const menuItems = [

    {MENU_1 : "Skolas preces", MENU_2: "Mugursomas, penāļi, aksesuāri", MENU_3: false},
    {MENU_1 : "Biroja mēbeles un konferenču iekārtas", MENU_2: "Krēsli", MENU_3: "Biroja krēsli"},
    {MENU_1 : "Biroja mēbeles un konferenču iekārtas", MENU_2: "Mēbeles, interjera elementi", MENU_3: "Galdi"},
    {MENU_1 : "Skolas preces", MENU_2: "Zīmēšanas un rasēšanas albumi, klades, vāciņi", MENU_3: false},
    {MENU_1 : "Skolas preces", MENU_2: "Piederumi zīmēšanai", MENU_3: false},
    {MENU_1 : "Skolas preces", MENU_2: "Plastilīns un veidošanas masas", MENU_3: false},
    {MENU_1 : "Skolas preces", MENU_2: "Skolas komplekti", MENU_3: false},
    {MENU_1 : "Skolas preces", MENU_2: "Līme", MENU_3: false},
    {MENU_1 : "Skolas preces", MENU_2: "Teksta marķieri", MENU_3: false},
    {MENU_1 : "Rakstāmlietas", MENU_2: "Lodīšu pildspalvas", MENU_3: false},
    {MENU_1 : "Rakstāmlietas", MENU_2: "Pildspalvas rolleri", MENU_3: false},
    {MENU_1 : "Rakstāmlietas", MENU_2: "Zīmuļi", MENU_3: false},
    {MENU_1 : "Rakstāmlietas", MENU_2: "Zīmuļi, grafīts", MENU_3: false},
    {MENU_1 : "Rakstāmlietas", MENU_2: "Zīmuļu asināmie", MENU_3: false},
    {MENU_1 : "Rakstāmlietas", MENU_2: "Dzēšgumijas", MENU_3: false},
    {MENU_1 : "Rakstāmlietas", MENU_2: "Akcija", MENU_3: false},
    {MENU_1 : "Biroja preces", MENU_2: "Rakstāmgalda piederumi", MENU_3: "Šķēres"},
    
  ]
  
  let menu1Dec = menu1 ? decodeURIComponent(menu1.replace(/-/g, ' ')) : null;
  let menu2Dec = menu2 ? decodeURIComponent(menu2.replace(/-/g, ' ')) : null;
  let menu3Dec = menu3 ? decodeURIComponent(menu3.replace(/-/g, ' ')) : null;
  
  let productDec = product ? product : null;

  //const [menuItems, setMenuItems] = useState([]);
  
  const [email, setEmail] = useState("");
  
  const [isLoading, setIsLoading] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);
  
  const [productKeywords, setProductKeywords] = useState(false);
  const [categoryKeywords, setCategoryKeywords] = useState(false);
  const [showMobileAdd, setShowMobileAdd] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  
  const REACT_APP_API_SERVER = process.env.REACT_APP_API_SERVER;
  
  const { auth, user } = useFirebase();
  useEffect(() => {
    const updateProductsPerPage = () => {
      if (window.innerWidth < 768) {
        setShowMobileAdd(true);
      } else {
        setShowMobileAdd(false);
      }
    };
    updateProductsPerPage();
    window.addEventListener('resize', updateProductsPerPage);

    if (auth) {
      return () => {
        window.removeEventListener('resize', updateProductsPerPage);
        setAuthLoading(false); 
      };
    } else {
      setAuthLoading(false); 
    }
  }, [REACT_APP_API_SERVER, auth]);

  
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };


  //Izlasam pirmos četru vārdus no produkta nosaukuma
  function shortenKeyword(productName) {
    const words = productName.split(' ');
    const keywordArray = words.slice(0, 4);
    const keyword = keywordArray.join(' ');
    return keyword;
  }
  

  useEffect(() => {
    if (menuItems.length >0) {
      const keywords = menuItems.map(item => shortenKeyword(item.MENU_3 ? item.MENU_3 : item.MENU_2 ? item.MENU_2 : item.MENU_1 ? item.MENU_1 : null)).join(', ');
      setCategoryKeywords(keywords);
    }
  }, [menuItems]);
  
  let jsonLD;
   if (menuItems.length > 0){
    jsonLD = {
      "@context": "https://schema.org",
      "@type": "ItemList",
      "name": `Back to school!'}`,
      "itemListElement": menuItems.map((item, index) => ({
        "@type": "ListItem",
        "position": index + 1,
        "url": `https://www.officeshop.lv/categories/}/${encodeURIComponent(item.MENU_1 ? item.MENU_1.replace(/ /g, '-') : item.MENU_2 ? item.MENU_2.replace(/ /g, '-') : item.MENU_3 ? item.MENU_3.replace(/ /g, '-'): null)}`,
        "name": item.MENU_3 ? item.MENU_3 : item.MENU_2 ? item.MENU_2 : item.MENU_1,
        "image": `https://www.officeshop.lv/catImages/${item.MENU_3 ? item.MENU_3 : item.MENU_2 ? item.MENU_2 : item.MENU_1}.jpg`,
        "description": `${item.MENU_3 ? item.MENU_3 : item.MENU_2 ? item.MENU_2 : item.MENU_1} plašā sortimentā, pieejams uzreiz no noliktavas. Ātra piegāde un iepriecinošas cenas. Apskati piedāvājumu jau tagad! www.OfficeShop.lv`
      }))
    };
  }
  
  
  
  return (
    <>
      <Helmet>
      <title>Back to school - OfficeShop.lv</title>
        <meta name="description" content={`${menu3Dec !== null ? menu3Dec : menu2Dec !== null ? menu2Dec : menu1Dec !== null ? menu1Dec: 'Izvēlies no vairāk kā 30`000 precēm'} - atrodi sev nepieciešamo | OfficeShop.lv`} />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content={productKeywords ? productKeywords : categoryKeywords} />
        <meta property="og:title" content={`${menu3Dec !== null ? menu3Dec : menu2Dec !== null ? menu2Dec : menu1Dec !== null ? menu1Dec: 'Biroja, skolas un elektro preces'} - OfficeShop.lv`} />
        <meta property="og:description" content={`${menu3Dec !== null ? menu3Dec : menu2Dec !== null ? menu2Dec : menu1Dec !== null ? menu1Dec: 'Izvēlies no vairāk kā 30`000 precēm'} - atrodi sev nepieciešamo | OfficeShop.lv`} />
        <meta property="og:url" content={`https://www.officeshop.lv${location.pathname}`} />
        <link rel="canonical" href={`https://www.officeshop.lv${location.pathname}`} />
        <meta property="og:image" content={`${menu3Dec !== null ? `https://www.officeshop.lv/catImages/${menu3Dec}.jpg` : menu2Dec !== null ? `https://www.officeshop.lv/catImages/${menu2Dec}.jpg` : menu1Dec !== null ? `https://www.officeshop.lv/catImages/${menu1Dec}.jpg`: `https://www.officeshop.lv/prodImages/logo.png`} `} />
        
        <meta property="og:image:alt" content={`${menu3Dec !== null ? menu3Dec : menu2Dec !== null ? menu2Dec : menu1Dec !== null ? menu1Dec: 'Produktu kategorijas'} - OfficeShop.lv`} />
        <meta property="og:type" content="website" />
        {jsonLD && <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>}
      </Helmet>
      {showMobileAdd && <Mobile />}
      
      {isLoading ? <div className='product-page'>
        <img src='/images/loading.gif' alt='loading' />
      </div> : null}
        <div className="product-page">
         {/* <Slideshow products={menuItems} menu1Dec={menu1Dec} menu2Dec={menu2Dec} menu3Dec={menu3Dec} /> */}
            {menuItems.map((item, index) => (
              <div className="product">
                <div className="category-card">
              <Link
                key={index}
                to={
                  item.MENU_3
                    ? `/categories/${encodeURIComponent(item.MENU_1).replace(
                      /%20/g,
                      '-'
                    )}/${encodeURIComponent(item.MENU_2).replace(
                      /%20/g,
                      '-'
                    )}/${encodeURIComponent(item.MENU_3).replace(
                      /%20/g,
                      '-'
                    )}`
                    : item.MENU_2
                      ? `/categories/${encodeURIComponent(item.MENU_1).replace(
                        /%20/g,
                        '-'
                      )}/${encodeURIComponent(item.MENU_2).replace(
                        /%20/g,
                        '-'
                      )}`
                      : item.MENU_1
                        ? `/categories/${encodeURIComponent(item.MENU_1).replace(
                          /%20/g,
                          '-'
                        )}`
                        : null
                }
                className="product-header"
                onClick={scrollToTop}
              >
                <div className='image-container'>
                  <img src={`/catImages/` + (item.MENU_3 ? item.MENU_3 : item.MENU_2 ? item.MENU_2 : item.MENU_2 ? item.MENU_2 : null) + `.jpg`} alt={item.MENU_3 ? item.MENU_3 : item.MENU_2 ? item.MENU_2 : item.MENU_2 ? item.MENU_2 : null}  />
                </div>
                <div className="product-header">
                {item.MENU_3 ? item.MENU_3 : item.MENU_2 ? item.MENU_2 : item.MENU_2 ? item.MENU_2 : null}
                </div>
              </Link>
              </div>
              </div>
            ))}
          </div>
    </>
  );
};

export default BackToSchool;
