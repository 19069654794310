import React, { useState, useEffect } from "react";
import { GetItems } from "..//ProdHandleLogic.js";
import { Link } from "react-router-dom";
import './Drop.css'
import { IoReorderThreeOutline } from "react-icons/io5";

export function AllCategoriesDrop() {
    const [menuItems, setMenuItems] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        GetItems(null, null, null, null, null)
            .then((data) => {
                setMenuItems(data.menu);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const handleClick = (e) => {
        e.preventDefault();
        setShow(!show);
    };
    
    return (
        <div className="main-container">
            <div className="virsraksts" onClick={handleClick}>
                <IoReorderThreeOutline
                    className="thirdRowIcon"
                />
                <div>Preču kategorijas</div>
            </div>
            {show === true && <div className="contents">
                {menuItems.map((item) => (
                    <Link onClick={(e) => {e.preventDefault(); setShow(!show);  window.location.href = `/categories/${item.MENU_1.replace(/\s/g, '-')}` }}>
                        <div key={item}>
                            {item.MENU_1}
                        </div>
                    </Link>
                ))}
            </div>
            }
        </div>
    );
}
