import React, { useEffect, useState } from "react";
import useFirebase from "../../firebase/usefirebase";
import { onAuthStateChanged } from "firebase/auth";
import ProductCard from '../productCard/ProductCard';
import {GetItems} from '..//ProdHandleLogic.js';
import { Helmet } from "react-helmet";


const Favorites = () => {
    const [favorites, setFavorites] = useState([]);
    const [email, setEmail] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [authLoading, setAuthLoading] = useState(true);
    const { auth, user } = useFirebase();
    const API_SERVER = process.env.REACT_APP_API_SERVER;

    useEffect(() => {
        if (auth) {
            const unsubscribe = onAuthStateChanged(auth, (user) => {
                if (user) {
                    setEmail(user.email);
                    fetch(`${API_SERVER}/favorites?Email=${user.email}`)
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error("Failed to fetch user data");
                            }
                            return response.json();
                            
                        })
                        .then((data) => {
                            const favoritesString = data[0].Favorites;
                            
                            if (favoritesString === null) {
                                setFavorites([]);
                                setIsLoading(false);
                            } else {
                            const favoritesArray = favoritesString.split(",");
                            setFavorites(favoritesArray);
                            }

                        })
                        .catch((error) => console.error(error))
                        .finally(() => setAuthLoading(false)); 

                } else {
                    setIsLoading(false);
                    setAuthLoading(false); 
                }
            });
            return () => {
                unsubscribe();
            };
        } else {
            setAuthLoading(false); 
          }
    }, [API_SERVER, auth]);
     
  
    const updateFavoritesAfterDelete = () => {
        if (authLoading) return; 
        fetch(`${API_SERVER}/favorites?Email=${email}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch user data");
                }
                return response.json();
            })
            .then((data) => {
                const favoritesString = data[0].Favorites;
                if (!favoritesString ) {
                    setFavorites([]);
                } else {
                const favoritesArray = favoritesString.split(",");
                setFavorites(favoritesArray);
                }
            })
            .catch((error) => console.error(error));
    };
    
    useEffect(() => {
    if (favorites.length > 0) {
        Promise.all(
            favorites.map((favorite) => {
                return GetItems(null, null, null, favorite)
                    .then((data) => {
                        if (data.item.length !== 0) {
                        return data.item;
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        return null; 
                    });
            })
        )
        .then((data) => {
            const dati = [];
            data.forEach((item) => {
                if (item) {
                    dati.push(item[0]);
                }
            setSelectedProducts(dati);
            setIsLoading(false);
            });
        })
        .catch((error) => {
            console.error(error);
        });
    }
    }, [favorites]);
    
    
    return (
        
        <div className="product-page">
            <Helmet>
        <title>Mani favorīti | Officeshop.lv</title>
        <meta name="description" content="Favorītu sadaļa Officeshop.lv vietnē." />
        <meta name="robots" content="noindex, nofollow" />
        <meta property="og:title" content="Mani favorīti | Officeshop" />
        <meta property="og:description" content="Favorītu sadaļa Officeshop.lv vietnē." />
        <meta property="og:url" content="https://www.officeshop.lv/favorites" />
        <meta property="og:image" content="https://www.officeshop.lv/prodImages/logo.png" />
        <meta property="og:type" content="website" />
        
      </Helmet>
        {isLoading ? <img src='/images/loading.gif' alt='loading' /> : email === null ?
        <div style={{display: "flex", flexDirection:"column", alignItems: "center", minHeight: "58vh"}}>
        <h4>Favorītu sadaļa pieejama reģistrētiem lietotājiem</h4>
        <p>Lūdzu reģistrējieties vai ienāciet savā profilā!</p>
        </div>
        :
        favorites.length === 0 ? 
        <div style={{display: "flex", flexDirection:"column", alignItems: "center", minHeight: "58vh"}}>
        <h4>Jums pašreiz nav atlasītu favorītu</h4>
        <p>Atlasiet favorītus no Jums interesējošiem produktiem un tie parādīsies šeit!</p>
        </div>
        :
          selectedProducts.map((item, index) => (
            <div key={index} className="product">
              <ProductCard
                name={item.NAME}
                price={item.PRICE}
                image={item.MENU_1 === "Officeshop ražotie kārtridži"?  '/prodImages/Small/Officeshop Cartridges (Small)/cartridges (Small).jpg':
                    item.MENU_1 === "Kopējošās lentas (ribbon)" ? '/prodImages/Small/Ribbon (Small)/ribbon (Small).jpg':
                    item.PICTURE ? item.PICTURE.startsWith('http') ? item.PICTURE : `/prodImages/Small/${item.SKU} (Small)/${item.SKU} (Small).${item.PICTURE}`:
                    `/prodImages/Small/logo (Small)/logo (Small).png`}
                quantity={item.QTY}
                discount={item.DISCOUNT}
                link={
                    item.MENU_3 === 'N/A'
                        ? `/categories/${encodeURIComponent(item.MENU_1).replace(
                              /%20/g,
                              '-'
                          )}/${encodeURIComponent(item.MENU_2).replace(
                              /%20/g,
                              '-'
                          )}/${item.SKU}`
                        : `/categories/${encodeURIComponent(item.MENU_1).replace(
                              /%20/g,
                              '-'
                          )}/${encodeURIComponent(item.MENU_2).replace(
                              /%20/g,
                              '-'
                          )}/${encodeURIComponent(item.MENU_3).replace(
                              /%20/g,
                              '-'
                          )}/${item.SKU}`
                }
                sku={item.SKU}
                onDeleteFav={() => {updateFavoritesAfterDelete();}}
                fav='yes'
              />
            </div>
          ))
        }
        </div>
        
    );
    
};

export default Favorites;