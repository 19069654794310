import React from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { onAuthStateChanged } from "firebase/auth";
import { AiOutlineCaretDown } from "react-icons/ai";
import useFirebase from "../../firebase/usefirebase";
import { useState, useEffect } from "react";
import { format } from 'date-fns';
import { GetItems } from "../ProdHandleLogic";
import useNotification  from "../Notifications/notificationHandler"
import { Helmet } from 'react-helmet';
import './users.css';


const UserOrders = () => {
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [notEnoughStock, setNotEnoughStock] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [authLoading, setAuthLoading] = useState(true);
    const showNotification = useNotification();
    const API_SERVER = process.env.REACT_APP_API_SERVER;
    const groupedOrders = {};
    const { auth, user } = useFirebase();

    useEffect(() => {
        if (auth) {
            const unsubscribe = onAuthStateChanged(auth, (user) => {
                if (user) {
                    fetch(`${API_SERVER}/orders?email=${user.email}`)
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error("Failed to fetch user data");
                            }
                            return response.json();
                        })
                        .then((data) => {
                            setOrders(data);
                            setIsLoading(false);
                        })
                        .catch((error) => console.error(error))
                        .finally(() => setAuthLoading(false)); 
                } else {
                    setIsLoading(false);
                    setAuthLoading(false); 
                }
            });
            return () => {
                unsubscribe();
            };
        } else {
            setAuthLoading(false); 
        }
    }, [API_SERVER, auth]);



    orders.forEach((order) => {
        const key = `${format(new Date(order.OrderDate), 'dd.MM.yyyy')}+${order.Invoice}+${order.Status}+${order.Carrier}+${order.TrackingNo}+${order.Pakomats}+${order.Street}, ${order.City}, ${order.PostCode}`;
        if (!groupedOrders[key]) {
            groupedOrders[key] = [];
        }
        groupedOrders[key].push(order);
    });

    const newOrder = [];
    const customizedOrder = [];

    const toggleTable = (orderKey) => {
        setSelectedOrder(selectedOrder === orderKey ? null : orderKey);
        setNotEnoughStock(false);
    }

    useEffect(() => {
        if (authLoading) return; 
        const fetchData = async () => {
            if (groupedOrders[selectedOrder]) {
                const promises = groupedOrders[selectedOrder].map(async (order) => {
                    try {
                        const data = await GetItems('', '', '', order.SKU);
                        const currentProduct = data.item[0];
                        newOrder.push({ sku: currentProduct.SKU, quantity: order.QTY });
                        if (currentProduct.QTY < 1) {
                            setNotEnoughStock(true);
                            return;
                        } else if (currentProduct.QTY < order.QTY) {
                            setNotEnoughStock(true);
                            customizedOrder.push({ sku: currentProduct.SKU, quantity: currentProduct.QTY });
                        } else {
                            customizedOrder.push({ sku: currentProduct.SKU, quantity: order.QTY });
                        }
                    } catch (error) {
                        console.error(error);
                    }
                });
                await Promise.all(promises);
                

            }
        };
        fetchData();
    }, [selectedOrder, groupedOrders]);


    const handleRepeatOrder = (orderType) => {
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        if (orderType) {
            newOrder.forEach((newItem) => {
                const existingItem = cart.find((item) => item.sku === newItem.sku);
                if (existingItem) {
                    existingItem.quantity += parseInt(newItem.quantity, 10);
                } else {
                    cart.push({ sku: newItem.sku, quantity: newItem.quantity });
                }
            });
        } else {
            customizedOrder.forEach((newItem) => {
                const existingItem = cart.find((item) => item.sku === newItem.sku);
                if (existingItem) {
                    existingItem.quantity += parseInt(newItem.quantity, 10);
                } else {
                    cart.push({ sku: newItem.sku, quantity: newItem.quantity });
                }
            });
        }
        localStorage.setItem('cart', JSON.stringify(cart));
        window.dispatchEvent(new Event('storage'));
        showNotification('Pasūtījums pievienota grozam!');
    }

    
    return (
        <div className="OrderContainer">
            <Helmet>
                <title>Pasūtījumu vēsture | Officeshop.lv</title>
                <meta name="description" content="Apskati savu pasūtījumu vēsturi vietnē Officeshop.lv" />
                <meta name="robots" content="noindex, nofollow" />
                <meta property="og:title" content="Pasūtījumu vēsture | Officeshop" />
                <meta property="og:description" content="Apskati savu pasūtījumu vēsturi vietnē Officeshop.lv" />
                <meta property="og:url" content="https://www.officeshop.lv/order-history" />
                <meta property="og:image" content="https://www.officeshop.lv/prodImages/logo.png" />
                <meta property="og:type" content="website" />
            </Helmet>   
            {isLoading ?
            <div className="product-page">
                <img src='/images/loading.gif' alt='loading' />
            </div>
            : orders.length === 0 ?
            <div style={{display: "flex", flexDirection:"column", alignItems: "center", minHeight: "58vh"}}>
            <h4>Jums pašreiz nav veiktu pasūtījumu</h4>
            <p>Veicot pasūtījumus, to vēsture parādīsies šeit!</p>
            </div>
            :
            Object.keys(groupedOrders).map((key) => (
                <div className="order-list-container" key={key}>
                    <div onClick={() => { toggleTable(key) }} 
                        className={selectedOrder === key ? "Order-list-main-active" : "Order-list-main"}>
                        <p>Pasūtījums Nr. {key.split('+')[1]} no {key.split('+')[0]}. 
                            Statuss: {key.split('+')[2] === "new" ? 'Gaida apmaksu' : key.split('+')[2] === "paymentOK" ? 'Apmaksa saņemta, izpildē' : key.split('+')[2] === "completed" ? 'Pabeigts' : null}
                        <AiOutlineCaretDown style={{ float: "right" }} />
                        </p>
                    </div>
                    {selectedOrder === key && (
                        <>
                        
                        {key.split('+')[3] && key.split('+')[4] ? (
                            <div className="order-summary">
                                <p className="order-key">Piegāde: </p> 
                                <p className="order-value">{key.split('+')[3] === "door-to-door" ? "DPD kurjera piegāde" : key.split('+')[3] === "Venipak" ? "Venipak kurjera piegāde" :`${key.split('+')[3]} pakomāts`}</p>
                                {key.split('+')[3] !== "Venipak" &&
                                <>
                                <p className="order-key2">Izsekošanas Nr.</p>
                                <p className="order-link" onClick={key.split('+')[3] === 'Omniva' ? () => window.open(`https://manaold.omniva.lv/track/${key.split('+')[4]}//?language=lv`, '_blank') :
                                 () => window.open(`https://www.dpdgroup.com/lv/mydpd/my-parcels/incoming?parcelNumber=${key.split('+')[4]}`, '_blank')}>{key.split('+')[4]}</p>
                                </>
                                }
                            </div>
                        ): null}
                       
                        {key.split('+')[3] === "door-to-door" || key.split('+')[3] === "Venipak" ? (
                            <div className="order-summary">
                                <p className="order-key">Adrese</p>
                                <p className="order-value">{key.split('+')[6]}</p>
                            </div>
                        ):
                        (
                            <div className="order-summary">
                                <p className="order-key">Pakomāta adrese</p>
                                <p className="order-value">{key.split('+')[5]}</p>
                            </div>
                        )
                        }
                        <div className="seperator"></div>
                        <div className="order-summary">
                                <p className="order-key">Pasūtījuma saturs:</p>
                            </div>
                            <div className="orders-items-container">
                                <div className="orders-items">
                                    <div className="product-image-container">
                                        <p className="orders-image">Attēls</p>
                                    </div>
                                    <p className="name-order-list">Apraksts</p>
                                    <p className="orders-cena">Gb.</p>
                                    <p className="orders-cena">Cena</p>
                                    <p className="orders-cena">Summa</p>
                                    <p className="orders-cena">Pieejamība</p>
                                </div>
                                {groupedOrders[key].map((order, index) => (
                                    console.log(order.PICTURE),
                                    <div className="orders-item" key={index}>
                                        <div className="product-image-container">
                                            <img src={
                                                order.MENU_1 === "Officeshop ražotie kārtridži" ? '/prodImages/Small/Officeshop Cartridges (Small)/cartridges (Small).jpg' :
                                                order.MENU_1 === "Kopējošās lentas (ribbon)" ? '/prodImages/Small/Ribbon (Small)/ribbon (Small).jpg' :
                                                order.PICTURE ? order.PICTURE.startsWith('http') ? order.PICTURE : `/prodImages/Small/${order.SKU} (Small)/${order.SKU} (Small).${order.PICTURE}` :
                                                            `/prodImages/Small/logo (Small)/logo (Small).png`} alt={order.NAME} className="image" />
                                        </div>
                                        <p className="name-order-list">{order.Description}</p>
                                        <p className="orders-cena">{order.QTY}</p>
                                        <p className="orders-cena">{order.SalesPrice}</p>
                                        <p className="orders-cena">€ {(order.QTY * order.SalesPrice).toFixed(2)}</p>
                                        <p className="orders-cena">
                                            {order.AvailableQTY < 1 ? (
                                                <FaTimesCircle color="red" title="Produkts nav noliktavā" />
                                            ) : (
                                                order.AvailableQTY < order.QTY ? (
                                                    <div title="Produkta atlikums noliktavā">{order.AvailableQTY}</div>
                                                ) : (
                                                    <FaCheckCircle color="green" title="Produkts ir noliktavā" />
                                                )
                                            )}

                                        </p>
                                    </div>
                                ))}
                                <div className="order-total-container">
                                    <div className="amount" >Kopā: € {groupedOrders[key].reduce((acc, order) => acc + order.QTY * order.SalesPrice, 0).toFixed(2)} </div>
                                    {notEnoughStock &&
                                        <>
                                            <p className="repeatOrderWarning">Atkārtotam pasūtījumam pašreiz noliktavā nav pieejams viss iepriekšējā pasūtījumā Nr. {key.split('+')[1]} esošais apjoms vai produkti.</p>
                                            <p className="repeatOrderWarning">Veicot pasūtījumu kā iepriekšējā pasūtījumā, Jūsu pasūtījuma izpildes termiņš var tikt pagarināts</p>
                                        </>
                                    }
                                    {notEnoughStock ?
                                        <>
                                            <button className="btn-small-red-right"  onClick={() => handleRepeatOrder(true)}>Pasūtīt visu</button>
                                            <button className="btn-small-red-right"  onClick={() => handleRepeatOrder(false)}>Pasūtīt pieejamo</button>
                                        </>
                                        :
                                        <button onClick={() => handleRepeatOrder(true)} className="btn-small-red-right">Atkārtot</button>}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            ))
            }
        </div>
    );
}


export default UserOrders;
