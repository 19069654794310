import React from 'react';
import { useState } from 'react';
import { GrClose } from 'react-icons/gr';
import { Container } from 'react-bootstrap';
import '../../App.css';


function Footer() {
    const [message, setMessage] = useState(false);
    const [header, setHeader] = useState(false);

    const Serviss = 'Uzticams serviss – Jūsu uzticamais partneris!<br> Mēs piedāvājam visu, kas nepieciešams – no kārtridžiem līdz augstas kvalitātes karbona lentēm, kancelejas precēm, biroja aprīkojumam, skolnieku komplektiem, somām un visiem starta komplektiem. <br>Mēs vienmēr rūpējamies par mūsu klientiem un nodrošinām visu vienā vietā.<br> Viegla saziņa, ērta iepirkšanās un ātra piegāde!';
    const Remonts = '<strong>Juridiskām personām</strong><br>piedāvājam biroja tehnikas remonta un apkopes ar izbraukumu pie klienta. <br><br>Mūsu <strong>augsti kvalificētie</strong> meistari veiks Jūsu biroja tehnikas remontu un apkopi iesakot labāko no risinājumiem konkrētajai situācijai.';
    const Piegade =
        `Nodrošinām piegādi visā Latvijā uz: <ul><li>Omvina pakomātiem</li><li> DPD pakomātiem</li><li>uz mājām vai birojiem ar DPD kurejerpastu</li></ul>
     Noliktavā esošājām precēm piegāde tiek nodrošināta 2-3 darba dienu laikā
     <br>
     <br><strong>Juridiskām personām</strong><br>nodrošinām <strong>bezmaksas</strong> piegādi ar DPD kurjeru uz Jūsu norādīto adresi pēc sadarbības līguma noslēgšanas`;
    const Noliktava = 'Mūsu veikalā Jūs atradīsiet vairāk kā 50`000 preces! <br><br>Taču mēs saprotam, ka ne vienmēr ir iespējams atrast vajadzīgo preci, tāpēc aicinam izmantot kontaktu formu un uzdot jautājumu par preci, kuru nevarat atrast mūsu veikalā. <br><br> Mēs atbildēsim Jums dienas laikā!';
    const Rekviziti = '<strong>Sia SR Logistics</strong> <br>Reģ. Nr. 40203462776 <br>PVN. Reģ. Nr. LV40203462776<br>Adrese: Mārupe <br>Banka: Citadele <br>Konts: LV60PARX0028217600001 '
    const Terms = `
    <style>
        .policy-container p, ul {
            font-size: 9px;
            line-height: 1.1;
            color: #333;
            margin-bottom: 2px;
            margin-top: 0;
        }
        .policy-container p {
            text-align: justify;
        }
        .policy-container strong {
            color: #000;
        }
        .policy-container ul {
            padding-left: 15px;
        }
        .policy-container li {
            margin-bottom: 2px;

        }
        
        .policy-container p strong {
            display: block;
            margin-bottom: 3px;
            font-size: 10px;
            font-weight: bold;
        }
        
        .policy-container ul {
            list-style: square;
            margin: 0;
            padding-left: 18px;
        }
        
        .policy-container {
            padding: 5px;
        }
    </style>

    <div class="policy-container">
    
    <p><strong>SIA SR LOGISTICS</strong> REĢISTRĀCIJAS NR. 40203462776, turpmāk – internetveikals www.officeShop.lv – nodrošina mājas lapā pieejamo saturu un sniedz preces/pakalpojumus saskaņā ar zemāk izklāstītajiem Lietošanas noteikumiem.</p>

    <p><strong>1. Vispārīgie noteikumi</strong></p>
    <p>Ja patērētājs, izmantojot mājas lapas starpniecību iegādājas preces/pakalpojumus, tad šāda savstarpēja vienošanās tiek uzskatīta par Distances līgumu un ir pakļauta Latvijas Republikas tiesību normām, kas regulē distances līgumu tai skaitā, bet ne tikai, Latvijas Republikas „Patērētāju tiesību aizsardzības likums”, Latvijas Republikas Ministru Kabineta noteikumiem „Noteikumi par distances līgumu” u.c.</p>

    <p><strong>2. Pirkumu veikšana</strong></p>
    <p>Interneta veikalā pārdoto produktu cenas un specifikācija ir norādītas blakus produktiem.</p>
    <p>Lai veiktu pasūtījumu, pievienojiet vēlamos produktus iepirkumu grozam. Aizpildiet visus nepieciešamos laukus un izvēlēties piemērotāko piegādes veidu. Pasūtījuma kopējās izmaksas ar piegādi pēc tam tiek parādītas uz ekrāna. Veiciet pirkuma apmaksu, lai pabeigtu pasūtījumu.</p>

    <p><strong>3. Apmaksas noteikumi</strong></p>
    <p>Norēķinu valūta vietnē ir eiro. Pirkumu iespējams apmaksāt izvēloties sekojošas maksājumu metodes, ko nodrošina maksājumu platforma makecommerce.lv, Maksekeskus AS:</p>
    <ul>
        <p>Latvijas internetbanku maksājumi: Swedbank, SEB, Citadele un Luminor</p>
        <p>Igaunijas internetbanku maksājumi: Swedbank, SEB, Luminor</p>
        <p>Lietuvas internetbanku maksājumi: Swedbank, SEB un Luminor</p>
        <p>Visa/Mastercard karšu maksājumi</p>
    </ul>

    <p><strong>5. Atteikuma tiesības</strong></p>
    <p>Pircējam ir tiesības atteikties no preces 14 kalendāro dienu laikā no Preces saņemšanas brīža. (Atkarībā no preces, pircējam var nebūt obligātas atteikuma tiesības, šādā gadījumā iemeslam ir jābūt skaidri norādītam un izskaidrotam). Atteikuma tiesības nav piemērojamas, ja Pircējs ir juridiska persona.</p>
    <p>Lai izmantotu 14 dienu atteikuma tiesības, preces drīkst izmantot tikai tām paredzētajiem mērķiem, patērētājs ir atbildīgs par preces kvalitātes un drošuma saglabāšanu atteikuma tiesību realizēšanas termiņā. Ja preces tiek nepiemēroti izmantotas vai bojātas, lietošanas laikā nevērīgi izturoties pret preci vai neievērojot instrukcijas norādījumus, ja ir nozaudēts preces oriģinālais iepakojums, vai, ja tās iepakojums ir būtiski bojāts, internetveikalam ir tiesības samazināt atmaksājamo summu atbilstoši preces vērtības samazinājumam.</p>
    <p>Lai izmantotu atteikuma tiesības, Jums jāiesniedz atteikuma veidlapu uz e-pasta adresi info@officeshop.lv 14 dienu laikā pēc preču saņemšanas. Ir laba prakse klientam nodrošināt preces atpakaļsūtīšanu bez papildus maksas. Tas palielinās uzticību internetveikalam, kas palīdzēs uzlabot pārdošanas apjomus.</p>
    <p>Nākamais teikums ir paredzēts tiešsaistes veikaliem, kuri nevar piedāvāt bezmaksas preces atgriešanas iespēju. Pircējs sedz preces atgriešanas izmaksas, izņemot gadījumus, kad iemesls atgriešanai ir tas, ka prece neatbilst pasūtījumam (piemēram, nepareizs vai bojāts produkts).</p>
    <p>Pircēja pienākums ir bez kavēšanās, bet ne vēlāk kā 14 dienu laikā pēc atteikuma veidlapas nosūtīšanas internetveikalam, atdot Pārdevējam Preces. Pēc atpakaļ nosūtīto preču saņemšanas internetveikals nekavējoties, bet ne vēlāk kā 14 dienu laikā, atmaksā visus no pircēja saņemtos maksājumus pamatojoties uz Distances līgumu.</p>
    <p>Pārdevējam ir tiesības aizturēt maksājumu līdz brīdim, kamēr nav saņēmis no Pircēja Preces vai preču atgriešanas apliecinājumu. Ja pircējs ir izvēlējies citu sūtīšanas veidu nekā lētāko internetveikalā piedāvāto sūtījumu veidu, internetveikalam nav pienākums kompensēt pārsniegtās sūtījuma izmaksas.</p>
    <p>Internetveikals nav atbildīgs par kavēšanos ar saistību izpildi vai to nepildīšanu, vai cita veida neizpildi, kas radusies tādu apstākļu un šķēršļu dēļ, kuri ir ārpus internetveikala saprātīgas kontroles.</p>
    <p>Internetveikals patur tiesības atteikties pārdot preces un pieprasīt preču atgriešanu no Pircēja, ja internetveikalā norādītā cena ir ievērojami mazāka par tās tirgus cenu kļūdas dēļ.</p>

    <p><strong>6. Patērētāja tiesības par līgumam neatbilstošu preci</strong></p>
    <p>Internetveikals ir atbildīgs par Pircējam pārdoto preču neatbilstību līguma noteikumiem vai trūkumiem kas radusies 6 mēnešu laikā pēc preču piegādes dienas klientam, vai bijusi piegādes brīdī, ja šāds pieņēmums nav pretrunā ar lietas īpašībām vai trūkumu. Pircējam ir jāinformē Pārdevējs par preču neatbilstību nekavējoties jāiesniedz sūdzība. Pircējs var iesniegt sūdzību, sazinoties ar internetveikalu, rakstot uz info@officeshop.lv.</p>
    <p>Internetveikals nav atbildīgs par defektiem, kas radušies pēc preču piegādes Pircējam. Ja iegādātajām precēm ir trūkumi, par kuriem ir atbildīgs Pārdevējs, Pircējam ir tiesības pieprasīt preču trūkumu novēršanu vai apmaiņu pret jaunām precēm bez maksas.</p>
    <p>Ja preces nav iespējams labot vai nomainīt, Pārdevējs atdod Pircējam visus Distances līgumā paredzētos maksājumus. Pārdevējs uz patērētāja sūdzību sniedz rakstisku atbildi 15 dienu laikā.</p>

    <p><strong>7. Pircēja personas datu apstrāde</strong></p>
    <p>Internetveikals apstrādā tikai tos personas datus, kurus pircējs ir ievadījis pie preču pasūtīšanas, kā piemēram, vārds, uzvārds, e-pasts u.c.</p>
    <p>Internetveikals nodod personas datus transporta pakalpojumu sniedzējam/iem, lai nodrošinātu preču piegādi.</p>
    <p>Ja Jūs esat nepārprotami piekritis saņemt mūsu mārketinga paziņojumus, tostarp jaunumus, mēs laiku pa laikam varam sazināties ar Jums nosūtot informāciju par mūsu pakalpojumiem un jaunākajiem piedāvājumiem. Šajā nolūkā mēs varam apstrādāt Jūsu e-pasta adresi, kuru norādījāt, pierakstoties mārketinga komunikācijai. Pircējam ir iespēja atteikties no mārketinga paziņojumiem, paziņojot par to mums, rakstot uz info@officeshop.lv.</p>

    <p><strong>8. Strīdu izšķiršanas kārtība</strong></p>
    <p>Jautājumos, kas nav atrunāti šajos noteikumos un nosacījumos, Pircējs un Pārdevējs apņemas ievērot Latvijas Republikā spēkā esošos normatīvos aktus.</p>
    <p>Puses visus starp Pārdevēju un Pircēju radušos strīdus risina savstaprēju sarunu vai sarakstes ceļā. Ja strīdu neizdosies atrisinās sarunu vai sarakstes veidā, Puses strīdu risinās Latvijas Republikas tiesā, ievērojot LR normatīvos aktus. Strīdus starp Pircēju (patērētāju) un Pārdevēju var nodot risināšanai Patērētāju tiesību aizsardzības centrā vai Latvijas Republikas tiesā.</p>
    <p>Pircējam ir tiesības vērsties arī Eiropas Savienības strīdu izšķiršanas iestādēs.</p>
</div>`;
    const Kontakti = 'E-pasts: info@officeshop.lv <br>Telefons: +371 26 828 918 <br><br>Ērtākai saziņai aicinam izmantot saziņas formu mājas lapā!<br>To atradīsi lapas augšpusē, spiežot uz vēstules ikonas <br><br>Atbildēsim Jums dienas laikā!'
    const Officeshop =
        `Officeshop ražotie kārtridži ir izgatavoti pēc visaugstākajiem standartiem, lai nodrošinātu augstu kvalitāti un ilgu kalpošanas laiku. 
    <br><br><strong>Mēs par to esam pārliecināti un piedāvājam garantiju uz visiem Officeshop ražotajiem kārtridžiem</strong>.
    <br><br>Kā tā izpaužas ?
    <br>Mēs bez maksas apmainīsim kārtridžu, ja tas ir bojāts vai nedarbojas pareizi.
    <br>Taču lielākā kvalitātes garantija ir mūsu klientu atsauksmes, kuras liecina, ka Officeshop ražotie kārtridži ir augstas kvalitātes un ļauj ietaupīt neekonomējot uz kvalitāti.
    <br><br>Kāpēc izvēlēties Officeshop ražotus kārtridžus?
    <ul>
    <li>Pievilcīga cena</li>
    <li>Garantija</li>
    <li>Augsta kvalitāte</li>
    </ul>
    <p>Ja Jums ir jautājumi par Officeshop ražotajiem kārtridžiem, aicinam sazināties ar mums!</p>`;

    const Politika = `
    <style>
        .policy-container p, ul {
            font-size: 9px;
            line-height: 1.1;
            color: #333;
            margin-bottom: 2px;
            margin-top: 0;
        }
        .policy-container p {
            text-align: justify;
        }
        .policy-container strong {
            color: #000;
        }
        .policy-container ul {
            padding-left: 15px;
        }
        .policy-container li {
            margin-bottom: 2px;

        }
        
        .policy-container p strong {
            display: block;
            margin-bottom: 3px;
            font-size: 10px;
            font-weight: bold;
        }
        
        .policy-container ul {
            list-style: square;
            margin: 0;
            padding-left: 18px;
        }
        
        .policy-container {
            padding: 5px;
        }
    </style>
    

    <div class="policy-container">
        <p><strong>1. Vispārīgie noteikumi</strong></p>
        <p>1.1. Šī Privātuma politika apraksta, kā “SIA SR LOGISTICS”, REĢ.NR. 40203462776, (turpmāk saukti arī – “Datu pārzinis”) iegūst, apstrādā un glabā personas datus, ko “OneStopOfficeShop” internetveikals iegūst no saviem klientiem un personām, kas apmeklē interneta mājaslapu (turpmāk saukti – “Datu subjekts” vai “Jūs”).</p>
        <p>1.2. Personas dati ir jebkura informācija attiecībā uz identificētu vai identificējamu fizisku personu, t. i., Datu subjektu. Apstrāde ir jebkura ar personas datiem saistīta darbība, piemēram, iegūšana, ierakstīšana, pārveidošana, izmantošana, skatīšana, dzēšana vai iznīcināšana.</p>
        <p>1.3. Datu pārzinis ievēro likumdošanā paredzētos datu apstrādes principus un spēj apstiprināt, ka personas dati tiek apstrādāti saskaņā ar spēkā esošo likumdošanu.</p>

        <p><strong>2. Personas datu iegūšana, apstrāde un glabāšana</strong></p>
        <p>2.1. Personu identificējošu informāciju Datu pārzinis iegūst, apstrādā un glabā, galvenokārt izmantojot internetveikala vietni un e-pastu. (NB! Nepieciešams papildināt, ja personas dati tiek apkopoti arī citā veidā, piemēram, papīra formā).</p>
        <p>2.2. Apmeklējot un izmantojot internetveikalā sniegtos pakalpojumus, Jūs piekrītat, ka jebkura sniegtā informācija tiek izmantota un pārvaldīta atbilstoši Privātuma politikā noteiktajiem mērķiem.</p>
        <p>2.3. Datu subjekts ir atbildīgs par to, lai iesniegtie personas dati būtu pareizi, precīzi un pilnīgi. Apzināta nepatiesas informācijas sniegšana tiek uzskatīta par mūsu Privātuma politikas pārkāpumu. Datu subjektam ir pienākums nekavējoties paziņot Datu pārzinim par jebkurām izmaiņām iesniegtajos personas datos.</p>
        <p>2.4. Datu pārzinis nav atbildīgs par zaudējumiem, kas nodarīti Datu subjektam vai trešajām personām, ja tie radušies nepatiesi iesniegtu personas datu dēļ.</p>

        <p><strong>3. Klientu personas datu apstrāde</strong></p>
        <p>3.1. Datu pārzinis var apstrādāt šādus personas datus:</p>
        <ul>
            <p>3.1.1. Vārds, uzvārds</p>
            <p>3.1.2. Kontaktinformācija (e-pasta adrese un/vai tālruņa numurs)</p>
            <p>3.1.3. Darījuma dati (iegādātās preces, piegādes adrese, cena, maksājuma informācija u.t.t.)</p>
            <p>3.1.4. Jebkuru citu informāciju, kura iesniegta mums vietnes piedāvāto pakalpojumu un preču iegādes laikā vai sazinoties ar mums.</p>
        </ul>
        <p>3.2. Papildus iepriekšminētajam, Datu pārzinim ir tiesības pārbaudīt iesniegto datu precizitāti, izmantojot publiski pieejamos reģistrus.</p>
        <p>3.3. Personas datu apstrādes juridiskais pamats ir Vispārīgās datu aizsardzības regulas 6.panta 1.punkta a), b), c) un f) apakšpunkts:</p>
        <ul>
            <p>a) datu subjekts ir devis piekrišanu savu personas datu apstrādei vienam vai vairākiem konkrētiem nolūkiem;</p>
            <p>b) apstrāde ir vajadzīga līguma, kura līgumslēdzēja puse ir datu subjekts, izpildei vai pasākumu veikšanai pēc datu subjekta pieprasījuma pirms līguma noslēgšanas;</p>
            <p>c) apstrāde ir vajadzīga, lai izpildītu uz pārzini attiecināmu juridisku pienākumu;</p>
            <p>f) apstrāde ir vajadzīga pārziņa vai trešās personas leģitīmo interešu ievērošanai, izņemot, ja datu subjekta intereses vai pamattiesības un pamatbrīvības, kurām nepieciešama personas datu aizsardzība, ir svarīgākas par šādām interesēm, jo īpaši, ja datu subjekts ir bērns.</p>
        </ul>
        <p>3.4. Datu pārzinis glabā un apstrādā Datu subjekta personas datus, kamēr pastāv vismaz viens no zemāk norādītajiem kritērijiem:</p>
        <ul>
            <p>3.4.1. Personas dati nepieciešami tiem nolūkiem, kam tie saņemti;</p>
            <p>3.4.2. Kamēr ārējos normatīvajos aktos noteiktajā kārtībā Datu pārzinis un/vai Datu subjekts var realizēt savas leģitīmās intereses, kā piemēram, iesniegt iebildumus vai celt vai vest prasību tiesā;</p>
            <p>3.4.3. Kamēr pastāv juridisks pienākums datus glabāt, kā piemēram, saskaņā ar Grāmatvedības likumu;</p>
            <p>3.4.4. Kamēr ir spēkā Datu subjekta piekrišana attiecīgajai personas datu apstrādei, ja nepastāv cits personas datu apstrādes likumīgs pamats.</p>
        </ul>
        <p>Izbeidzoties šajā punktā minētajiem apstākļiem, izbeidzas arī Datu subjekta personas datu glabāšanas termiņš un visi attiecīgie personas dati tiek neatgriezeniski izdzēsti no datoru sistēmām un elektroniskajiem un/vai papīra dokumentiem, kas saturējuši attiecīgos personas datus vai arī šie dokumenti tiek anonimizēti.</p>
        <p>3.5. Lai pildītu savas saistības pret Jums, Datu pārzinim ir tiesības nodot Jūsu personas datus sadarbības partneriem, datu apstrādātājiem, kas veic nepieciešamo datu apstrādi mūsu uzdevumā, piemēram, grāmatvežiem, kurjerdienestiem u.c. Datu apstrādātājs ir personas datu pārzinis. Maksājumu apstrādi nodrošina maksājumu platforma makecommerce.lv, tāpēc mūsu uzņēmums maksājumu izpildei nepieciešamos personas datus nodod platformas īpašniekam Maksekeskus AS.</p>
        <p>Pēc pieprasījuma mēs varam nodot Jūsu personas datus valsts un tiesībsargājošajām iestādēm, lai nepieciešamības gadījumā aizstāvētu savas juridiskās intereses, sastādot, iesniedzot un aizstāvot juridiskas prasības.</p>
        <p>3.6. Apstrādājot un glabājot personas datus, Datu pārzinis īsteno organizatoriskos un tehniskos pasākumus, lai nodrošinātu personas datu aizsardzību pret nejaušu vai nepkumīgu iznīcināšanu, mainīšanu, izpaušanu un jebkādu citu nelikumīgu apstrādi.</p>

        <p><strong>4. Datu subjekta tiesības</strong></p>
        <p>4.1. Saskaņā ar Vispārīgo datu aizsardzības regulu un Latvijas Republikas tiesību aktiem Jums ir tiesības:</p>
        <ul>
            <p>4.1.1. Piekļūt saviem personas datiem, saņemt informāciju par to apstrādi, kā arī pieprasīt Jūsu personas datu kopiju elektroniskā formātā un tiesības uz šo datu nodošanu citam pārzinim (datu pārnesamība);</p>
            <p>4.1.2. Pieprasīt labot nepareizus, neprecīzus vai nepilnīgus personas datus;</p>
            <p>4.1.3. Dzēst savus personas datus (“tikt aizmirstam”), izņemot gadījumus, kad likums pieprasa saglabāt datus;</p>
            <p>4.1.4. Atsaukt savu iepriekš sniegto piekrišanu personas datu apstrādei;</p>
            <p>4.1.5. Ierobežot savu datu apstrādi – tiesības pieprasīt, lai mēs īslaicīgi pavisam pārtraucam apstrādāt visus Jūsu personas datus;</p>
            <p>4.1.6. Vērsties Datu valsts inspekcijā.</p>
        </ul>
        <p>Pieprasījumu par savu tiesību īstenošanu varat iesniegt, aizpildot formu klātienē, vai arī iesūtot pieprasījumu elektroniski, rakstot uz klientu atbalsta dienestu info@officeshop.lv.</p>

        <p><strong>5. Nobeiguma noteikumi</strong></p>
        <p>5.1. Šī Privātuma politika izstrādāta saskaņā ar Eiropas Parlamenta un Padomes regulu (ES) 2016/679 (2016. gada 27. aprīlis) par fizisku personu aizsardzību attiecībā uz personas datu apstrādi un šādu datu brīvu apriti un ar ko atceļ Direktīvu 95/46/EK (Vispārīgā datu aizsardzības regula), kā arī Latvijas Republikas un Eiropas Savienības spēkā esošajiem likumiem.</p>
        <p>5.2. Datu pārzinim ir tiesības veikt izmaiņas vai papildinājumus Privātuma politikā jebkurā brīdī un bez iepriekšēja brīdinājuma. Labojumi stājas spēkā pēc to publicēšanas mājas lapā www.officeshop.lv.</p>
    </div>
`;


 
    return (
        <Container fluid className="footerContainer">
            <div className="footer-top">
                <div className="footerSection" onClick={() => { setMessage(Serviss); setHeader('Uzticams Serviss') }}>Uzticams serviss</div>
                <div className="footerSection" onClick={() => { setMessage(Officeshop); setHeader('Officeshop ražotie kārtridži') }}>Mūsu kārtridžu garantija</div>
                <div className="footerSection" onClick={() => { setMessage(Remonts); setHeader('Biroja Tehikas remonts') }}>Biroja tehnikas remots</div>
                <div className="footerSection" onClick={() => { setMessage(Piegade); setHeader('Piegāde') }}>Ātra un ērta piegāde</div>
                <div className="footerSection" onClick={() => { setMessage(Noliktava); setHeader('50`000+ preces') }}>50`000+ preces</div>
            </div>
            <div className="footerMid">
                <div className="midSection">
                    <p className='clickable-fotter-p' onClick={() => { setMessage(Rekviziti); setHeader('Mūsu Rekvizīti') }} >Rekvizīti</p>
                    <p className='clickable-fotter-p' onClick={() => { setMessage(Kontakti); setHeader('Sazinies ar mums') }}>Sazinies ar mums</p>
                    <p className='clickable-fotter-p' onClick={() => { setMessage(Politika); setHeader('Privātuma politika') }} >Privātuma politika</p>
                    <p className='clickable-fotter-p' onClick={() => { setMessage(Terms); setHeader('Lietošanas noteikumi') }}>Lietošanas noteikumi</p>
                </div>
                <div className="midSection">
                    <p>Darba laiks:</p>
                    <p>Darba dienās 8:00-17:00</p>
                    <p>Sestdienās un Svētdienās atpūšamies</p>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="footer-bottomSection">© 2024. Visas tiesības aizsargātas</div>
                <div className="footer-bottomSection">Created by: AA full service web solutions</div>
            </div>

            {message &&
                <div className='footerMessageContainer'>
                    <div className="footerMessage">
                        <div className='footerMessageHeaderContainer'>
                            <h2>{header}</h2>
                            <GrClose onClick={() => { setMessage(false); setHeader(false) }} className='icon'>Aizvērt</GrClose>
                        </div>
                        <div className='footerMessageTextContainer'>
                            {message === "Politika" || message === "Terms" ? <div className='footerLongText'>{message}</div> :
                            <p dangerouslySetInnerHTML={{ __html: message }}></p>
                        }
                        </div>
                        
                    </div>
                </div>
            }
            <div className='salidziniLV'>
        <a href="https://www.salidzini.lv/" target="_blank" rel="noreferrer"><img border="0" alt="Salidzini.lv logotips" title="Interneta veikali. Labākā cena" src="https://static.salidzini.lv/images/logo_button.gif"/></a>        
        </div>
        <div className='kurpirktLV'>
        <a href="https://www.kurpirkt.lv" title="Salīdzini cenas Latvijas interneta veikalos">
        <img border="0"  alt="Salīdzini cenas Latvijas interneta veikalos" src="//www.kurpirkt.lv/media/kurpirkt120.gif" width="120" height="40"/>
        </a>
        </div>
        </Container>

    )
}

export default Footer;