import React from "react";

import { MdOutlineNewReleases } from "react-icons/md";
import './Drop.css'


export function Lentas() {
    return (
        <div className="main-container">
            
            <div className="virsraksts" onClick={() => {window.location.href = "/categories/Kopējošās-lentas-(ribbon)/"; }}>
                <MdOutlineNewReleases
                    className="thirdRowIcon"
                />
                <div>Karbona lentas</div>
            </div>
            
        </div>
    );
}