import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './product.css';
import { GetItems } from '..//components/ProdHandleLogic';
import { useState } from 'react';
import ProductCard from '..//components/productCard/ProductCard';
import { FaDroplet } from "react-icons/fa6";
import { MdOutlineVerified } from "react-icons/md";
import { RiCloseCircleFill } from "react-icons/ri";
import { addToCart } from '..//context/cartFunctions'
import useNotification from "../components/Notifications/notificationHandler"
import { TbShoppingCartPlus } from "react-icons/tb";
import { TbTruckDelivery } from "react-icons/tb";
import { RiDiscountPercentFill } from "react-icons/ri";
import { getCart } from '..//context/cartFunctions';
import { Helmet } from 'react-helmet';



const ProductPage = ({ item }) => {
  const { menu1, menu2, menu3, sku } = useParams();
  let menu1Dec = decodeURIComponent(menu1.replace(/-/g, ' '));
  let menu2Dec = menu2 ? decodeURIComponent(menu2.replace(/-/g, ' ')) : null;
  let menu3Dec = menu3 ? decodeURIComponent(menu3.replace(/-/g, ' ')) : null;
  const [saderigie, setSaderigie] = useState([]);
  const [colorDrop, setColorDrop] = useState(false);
  const [pcsToCart, setPcsToCart] = useState(item[0].MINORDER !== null && item[0].MINORDER !== 0 ? item[0].MINORDER : 1);
  const [selectedImage, setSelectedImage] = useState();
  const [specImage, setSpecImage] = useState();
  const [showMobileImageFull, setShowMobileImageFull] = useState(false);
  const [showFreeDelivery, setShowFreeDelivery] = useState(false);
  const productUrl = window.location.href;
  const showNotification = useNotification();
  const [isLoading, setIsLoading] = useState(true);
  const API_SERVER = process.env.REACT_APP_API_SERVER;

  function hasAtLeastTwoDigits(str) {
    const digits = str.match(/\d/g);
    return digits && digits.length >= 3;
  }
  if (menu2Dec && hasAtLeastTwoDigits(menu2Dec)) {
    menu2Dec = null;
  }
  if (menu3Dec && hasAtLeastTwoDigits(menu3Dec)) {
    menu3Dec = null;
  }

  const updateTextSize = () => {
    if (item[0].TEXT) {
      if (window.innerWidth < 768) {
        item[0].TEXT = item[0].TEXT.replace(/font-size: 16px;/g, 'font-size: 11.5px; overflow: hidden; text-overflow: ellipsis;');
        item[0].TEXT = item[0].TEXT.replace(/width: 100%;/g, 'width: 99%;');
      } else {
        item[0].TEXT = item[0].TEXT.replace(/font-size: 12px;/g, 'font-size: 16px;');
      }
    }
  };


  useEffect(() => {
    

    fetch(`${API_SERVER}/saderigie?SKU=${item[0].SKU}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then((data) => {
        if (Array.isArray(data) && data.length > 0 && data[0].hasOwnProperty('Saderigie')) {
          Promise.all(data.map((product) => {
            return GetItems(null, null, null, product.Saderigie)
              .then((data) => data.item)
              .catch((error) => {
                console.error(error);
                throw error;
              });
          })).then((saderigieProdukti) => {
            setSaderigie(saderigieProdukti);
            
          }).catch((error) => {
            console.error('Error:', error);
          });
        } else {
          GetItems(menu1Dec, menu2Dec, menu3Dec, null)
            .then((data) => {
              const produkti = [];              
              for (let i = 0; i < data.products.length && produkti.length < 10; i++) {
                if (data.products[i].QTY > 0) {
                  produkti.push([data.products[i]]);
                }
              }
              const filteredProdukti = [
                ...produkti.filter(item => item[0].DISCOUNT !== null),
                ...produkti.filter(item => item[0].DISCOUNT === null)
              ];

              setSaderigie(filteredProdukti);
              
            })
            .catch((error) => {
              console.error(error);
              throw error;
            });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });


    const mainPhoto = document.querySelector('.main-photo');
    if (mainPhoto) {
      mainPhoto.addEventListener('mousemove', (event) => {
        const rect = mainPhoto.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        mainPhoto.style.transformOrigin = `${x}px ${y}px`;
      });
    }

    window.addEventListener('resize', updateTextSize);
    updateTextSize();
    setIsLoading(false);

    return () => {
      window.removeEventListener('resize', updateTextSize);
      
    };

  }, [item, menu1Dec, menu2Dec, menu3Dec, setSaderigie]);



  useEffect(() => {
    if (menu1Dec === "Officeshop ražotie kārtridži") {
      setSpecImage(`/prodImages/Small/Officeshop Cartridges (Small)/cartridges (Small).jpg`);
    }
    if (menu1Dec === "Kopējošās lentas (ribbon)") {
      setSpecImage(`/prodImages/Small/Ribbon (Small)/ribbon (Small).jpg`);
    }

  }, [sku, menu1Dec, menu2Dec]);

  useEffect(() => {
    setSelectedImage(specImage ? specImage : item[0].PICTURE && item[0].PICTURE.startsWith('http') ? item[0].PICTURE : item[0].PICTURE ? `/prodImages/FullSize/${item[0].SKU}/${item[0].SKU}.jpg` : `/prodImages/Small/logo (Small)/logo (Small).png`);
    if (menu1.includes("kārtridži")) {
      if (item[0].NAME.includes("Dzeltens")) {
        setColorDrop('Yellow');
      } else if (item[0].NAME.includes("Ciānzils")) {
        setColorDrop('#00FFFF');
      } else if (item[0].NAME.includes("Fuksina")) {
        setColorDrop('FF00FF');
      } else if (item[0].NAME.includes("Melns")) {
        setColorDrop('Black');
      } else {
        setColorDrop(false);
      }
    }

  }, [item, specImage]);


  const handleAddToCart = async () => {
    setShowFreeDelivery(false);
    const cartOld = getCart();
    const serializedCartOld = encodeURIComponent(JSON.stringify(cartOld));
    const responseOld = await fetch(`${API_SERVER}/checkout?cart=${serializedCartOld}`);
    if (!responseOld.ok) {
      throw new Error("Failed to fetch cart data");
    }
    const resOld = await responseOld.json();
    const deliveryOld = resOld.delivery;
    showNotification('Prece pievienota grozam!');
    addToCart(item[0].SKU, parseInt(pcsToCart, 10));
    const cart = getCart();
    const serializedCart = encodeURIComponent(JSON.stringify(cart));
    const response = await fetch(`${API_SERVER}/checkout?cart=${serializedCart}`);
    if (!response.ok) {
      throw new Error("Failed to fetch cart data");
    }
    const res = await response.json();
    const delivery = res.delivery;
    if (deliveryOld.length !== 0 && delivery.length === 0) {
      setShowFreeDelivery(true);
    }
  };
  
  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const getNextDayDate = () => {
    const now = new Date();
    const nextDay = new Date(now);
    nextDay.setDate(now.getDate() + 1); 
    nextDay.setHours(23, 59, 0); 
    return nextDay.toISOString().split('T')[0] + 'T23:59:00'; 
  };

  const productJsonLD = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    'name': item[0].NAME,
    'image': item[0].PICTURE && item[0].PICTURE.startsWith('http') ? item[0].PICTURE : specImage ? `https://www.officeshop.lv${specImage}` : `https://www.officeshop.lv/prodImages/Small/${item[0].SKU} (Small)/${item[0].SKU} (Small).${item[0].PICTURE}`,
    'description': item[0].NAME + '. Pasūti tagad! Ātra piegāde un iepriecinošas cenas. Izvēlies no vairāk kā 30`000 precēm! www.OfficeShop.lv',
    'brand': {
      '@type': 'Brand',
      'name': item[0].BRAND
    },
    'offers': {
      '@type': 'Offer',
      'priceCurrency': 'EUR',
      'price': item[0].PRICE,
      'priceValidUntil': getNextDayDate(),
      'availability': item[0].QTY > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
      'url': productUrl,
      'itemCondition': 'https://schema.org/NewCondition',
      'seller': {
        '@type': 'Organization',
        'name': 'OfficeShop.lv',
        'url': 'https://www.officeshop.lv'
      },
      'eligibleQuantity': {
      '@type': 'QuantitativeValue',
      'value': item[0].QTY
      },
      'hasMerchantReturnPolicy': {
        '@type': 'MerchantReturnPolicy',
        'returnPolicyCategory': 'https://schema.org/Refund',
        'merchantReturnDays': 14,
      },
      'shippingDetails': {
        '@type': 'OfferShippingDetails',
        'shippingRate': {
          '@type': 'MonetaryAmount',
          'currency': 'EUR',
          'value': "2.49"
        }
      }
    }
  };

  function generateKeywords(item) {
    const keywords = new Set();
    const extractWordWithNumbers = (name) => {
      const regex = /\b(?!\w*\(\w*\))\w*\d+\w*\b/g;
      const matches = name.match(regex);
      return matches ? matches[0] : null;
    };
    
    if (item[0].BRAND) {
      if (item[0].NAME) {
        if (item[0].TEXT && item[0].PICTURE && item[0].PICTURE.startsWith('http')) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(item[0].TEXT, 'text/html');
          function extractInfo(label) {
            const tdElements = Array.from(doc.querySelectorAll('td'));
            for (let i = 0; i < tdElements.length; i++) {
              if (tdElements[i].textContent.trim() === label) {
                return tdElements[i + 1].textContent.trim();
              }
            }
            return null;
          }
          const modelNumber = extractInfo('Modeļa nr.');
          const productDescription = extractInfo('Modelis');
          let model = item[0].NAME.split(" ").pop();
          if (model.toLowerCase().includes(item[0].BRAND.toLowerCase())) {
            const words = item[0].NAME.split(" ");
            if (words.length >= 2) {
              model = words[words.length - 2];
            }
          }
          keywords.add(`${item[0].BRAND} ${model}`);
          keywords.add(model);


          modelNumber && keywords.add(`${item[0].BRAND} ${modelNumber}`);
          productDescription && keywords.add(`${item[0].BRAND} ${productDescription}`);
          modelNumber && keywords.add(`${!menu1Dec.includes("kārtridži") ? item[0].BRAND : ""} ${modelNumber} ${menu3Dec ? menu3Dec : menu2Dec ? menu2Dec : menu1Dec} ${menu1Dec === "Kopējošās lentas (ribbon)" ? "thermal transfer ribbon" : menu1Dec.includes("kārtridži") ? " printeru kārtridži" : ""}`);
          modelNumber && keywords.add(`${!menu1Dec.includes("kārtridži") ? item[0].BRAND : ""} ${modelNumber} ${menu3Dec ? menu3Dec : menu2Dec ? menu2Dec : menu1Dec} ${menu1Dec === "Kopējošās lentas (ribbon)" ? "thermal transfer ribbon" : menu1Dec.includes("kārtridži") ? " printeru kārtridži" : ""}pieejams tūlīt`);
          modelNumber && keywords.add(`${!menu1Dec.includes("kārtridži") ? item[0].BRAND : ""} ${modelNumber} ${menu3Dec ? menu3Dec : menu2Dec ? menu2Dec : menu1Dec} ${menu1Dec === "Kopējošās lentas (ribbon)" ? "thermal transfer ribbon" : menu1Dec.includes("kārtridži") ? " printeru kārtridži" : ""}laba cena`);
          modelNumber && keywords.add(`${!menu1Dec.includes("kārtridži") ? item[0].BRAND : ""} ${modelNumber} ${menu3Dec ? menu3Dec : menu2Dec ? menu2Dec : menu1Dec} ${menu1Dec === "Kopējošās lentas (ribbon)" ? "thermal transfer ribbon" : menu1Dec.includes("kārtridži") ? " printeru kārtridži" : ""}ātra piegāde`);
        } else {
          
          menu1Dec.includes("kārtridži") && extractWordWithNumbers(item[0].NAME) && keywords.add(`${item[0].BRAND} ${extractWordWithNumbers(item[0].NAME)}`);
          keywords.add(`${menu1Dec === "Kopējošās lentas (ribbon)" ? "thermal transfer ribbon" : menu1Dec.includes("kārtridži") ? "printeru kārtridži" : item[0].BRAND}`);
          keywords.add(`${!menu1Dec.includes("kārtridži") ? item[0].BRAND : ""} ${menu3Dec ? menu3Dec : menu2Dec ? menu2Dec : menu1Dec} ${menu1Dec === "Kopējošās lentas (ribbon)" ? "thermal transfer ribbon" : menu1Dec.includes("kārtridži") ? " printeru kārtridži" : ""}`);
          keywords.add(`${!menu1Dec.includes("kārtridži") ? item[0].BRAND : ""} ${menu3Dec ? menu3Dec : menu2Dec ? menu2Dec : menu1Dec} ${menu1Dec === "Kopējošās lentas (ribbon)" ? "thermal transfer ribbon" : menu1Dec.includes("kārtridži") ? "printeru kārtridži" : ""} pieejams tūlīt`);
          keywords.add(`${!menu1Dec.includes("kārtridži") ? item[0].BRAND : ""} ${menu3Dec ? menu3Dec : menu2Dec ? menu2Dec : menu1Dec} ${menu1Dec === "Kopējošās lentas (ribbon)" ? "thermal transfer ribbon" : menu1Dec.includes("kārtridži") ? "printeru kārtridži" : ""} laba cena`);
          keywords.add(`${!menu1Dec.includes("kārtridži") ? item[0].BRAND : ""} ${menu3Dec ? menu3Dec : menu2Dec ? menu2Dec : menu1Dec} ${menu1Dec === "Kopējošās lentas (ribbon)" ? "thermal transfer ribbon" : menu1Dec.includes("kārtridži") ? "printeru kārtridži" : ""} ātra piegāde`);
          menu3Dec !== null && keywords.add(`${menu3Dec}`);
          menu2Dec !== null && keywords.add(`${menu2Dec}`);
          keywords.add(`${menu1Dec}`);

        }
        const model = item[0].NAME.split(" ")[1];
        if (model !== item[0].BRAND) {
          keywords.add(`${item[0].BRAND} ${model}`);
          keywords.add(`${item[0].BRAND} ${model} ātra piegāde`);
          keywords.add(`${item[0].BRAND} ${model} pieejams tūlīt`);
          keywords.add(`${item[0].BRAND} ${model} saņem jau rīt`);
        }
        const firstThree = item[0].NAME.split(" ").slice(0, 3).join(" ");
        keywords.add(`${item[0].BRAND} ${firstThree}`);
        const firstTwo = item[0].NAME.split(" ").slice(0, 2).join(" ");
        const first = item[0].NAME.split(" ")[0];
        keywords.add(`${item[0].BRAND} ${firstTwo} cena`);
        keywords.add(`${menu1Dec === "Kopējošās lentas (ribbon)" ? first : `${firstTwo} cena`}`);
      }
    }  else {
      const firstThree = item[0].NAME.split(" ").slice(0, 3).join(" ");      
      keywords.add(`${menu3Dec ? menu3Dec : menu2Dec} ${firstThree}`);
      const firstTwo = item[0].NAME.split(" ").slice(0, 2).join(" ");
      const first = item[0].NAME.split(" ")[0];
      keywords.add(`${menu3Dec ? menu3Dec : menu2Dec} ${firstTwo} cena`);
      keywords.add(`${menu1Dec === "Kopējošās lentas (ribbon)" ? first : `${firstTwo} cena`}`);
    }
    return Array.from(keywords).join(', ');
  }

  const showImage = (image) => {
    setShowMobileImageFull(!showMobileImageFull);
  }

  const keywords = generateKeywords(item);
  
  return (
    <>
      <Helmet>
        <title>{item[0].NAME} - OfficeShop.lv</title>
        <meta name="description" content={item[0].NAME + '. Pasūti tagad! Ātra piegāde un iepriecinošas cenas. Izvēlies no vairāk kā 30`000 precēm! www.OfficeShop.lv'} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={`${item[0].NAME} - Officeshop`} />
        <meta name="keywords" content={keywords} />
        <meta property="og:description" content={item[0].NAME + '. Pasūti tagad! Ātra piegāde un iepriecinošas cenas. Izvēlies no vairāk kā 30`000 precēm! www.OfficeShop.lv'} />
        <meta property="og:url" content={productUrl} />
        <meta property="og:image" content={`${item[0].PICTURE && item[0].PICTURE.startsWith('http') ? item[0].PICTURE : specImage ? `https://www.officeshop.lv${specImage}` : 'https://www.officeshop.lv' + selectedImage}`} />
        <meta property="og:image:alt" content={`${item[0].NAME} Image`} />
        <meta property="og:type" content="product" />
        <script type="application/ld+json">{JSON.stringify(productJsonLD)}</script>
      </Helmet>
      {isLoading ? <div className='product-page'>
        <img src='/images/loading.gif' alt='loading' />
      </div> : (
        <>
          <div className="product-container">
            {showMobileImageFull &&
              <div className='fullImageMobileContainer'>
                <RiCloseCircleFill
                  className='closeIcon'
                  onClick={() => setShowMobileImageFull(false)}
                />
                <img src={selectedImage} alt={item[0].NAME}/>
              </div>}
            <div class="pa-kreisi">
              {item[0].DISCOUNT !== null ? (
                <div className='discountContainerProd'>
                  <RiDiscountPercentFill className='discounticonProd' />
                  <p className='discountValueProd'>-{((item[0].DISCOUNT - 1) * 100).toFixed(0)}%</p>
                </div>
              ) : null}
              <img
                src={selectedImage}
                alt={item[0].NAME}
                className={window.innerWidth < 768 ? 'main-photo-mobile' : 'main-photo'}
                onClick={() => window.innerWidth < 768 && showImage()}
              />
              <div className="small-photos">
                {item[0].PICTURE1 &&
                  <>
                    <img
                      src={`/prodImages/FullSize/${item[0].SKU}/${item[0].SKU}.jpg`}
                      alt={item[0].NAME}
                      className='photo-album'
                      onClick={() => handleImageClick(`/prodImages/FullSize/${item[0].SKU}/${item[0].SKU}.jpg`)}
                    />
                    <img
                      src={`/prodImages/FullSize/${item[0].SKU} 2/${item[0].SKU} 2.jpg`}
                      alt={item[0].NAME}
                      className='photo-album'
                      onClick={() => handleImageClick(`/prodImages/FullSize/${item[0].SKU} 2/${item[0].SKU} 2.jpg`)}
                    />
                  </>
                }
                {item[0].PICTURE2 &&
                  <img
                    src={`/prodImages/FullSize/${item[0].SKU} 3/${item[0].SKU} 3.jpg`}
                    alt={item[0].NAME}
                    className='photo-album'
                    onClick={() => handleImageClick(`/prodImages/FullSize/${item[0].SKU} 3/${item[0].SKU} 3.jpg`)}
                  />
                }
              </div>
            </div>
            <div class="right-col">
              <h1 className='product-nosaukums'>{colorDrop !== false ? <FaDroplet style={{ color: `${colorDrop}`, width: "20px", height: "20px" }} /> : null}{item[0].NAME}</h1>
              {item[0].DISCOUNT !== null ?
                <p className='product-cena' style={{ textDecoration: "line-through", color: "#B0B0B0" }}>€ {(item[0].PRICE * item[0].DISCOUNT).toFixed(2)}{item[0].UNIT && `/${item[0].UNIT}`}</p>
                :
                <>
                  <p className='product-cena'>€ {item[0].PRICE}{item[0].UNIT && `/${item[0].UNIT}`}</p>
                  <p className='attributesNoVat'>bez PVN € {(item[0].PRICE / 1.21).toFixed(2)}</p>
                </>}
              {item[0].DISCOUNT !== null &&
                <>
                  <p className='product-cena' style={{ color: "#f70d1a" }}>€ {item[0].PRICE}{item[0].UNIT && `/${item[0].UNIT}`}</p>
                  <p className='attributesNoVat' style={{ color: "#f70d1a" }}>bez PVN € {(item[0].PRICE / 1.21).toFixed(2)}</p>
                </>
              }
              {item[0].ATTRIBUTE_NAME1 ? <p className='attributes'>{item[0].ATTRIBUTE_NAME1}: {item[0].ATTRIBUTE_VALUE1}</p> : null}
              {item[0].ATTRIBUTE_NAME2 ? <p className='attributes'>{item[0].ATTRIBUTE_NAME2}: {item[0].ATTRIBUTE_VALUE2}</p> : null}
              {item[0].ATTRIBUTE_NAME3 ? <p className='attributes'>{item[0].ATTRIBUTE_NAME3}: {item[0].ATTRIBUTE_VALUE3}</p> : null}
              {item[0].ATTRIBUTE_NAME4 ? <p className='attributes'>{item[0].ATTRIBUTE_NAME4}: {item[0].ATTRIBUTE_VALUE4}</p> : null}
              {item[0].ATTRIBUTE_NAME5 ? <p className='attributes'>{item[0].ATTRIBUTE_NAME5}: {item[0].ATTRIBUTE_VALUE5}</p> : null}



              {item[0].QTY > 0 ? item[0].QTY < 11 ?
                <div className='noliktava'>
                  <p><MdOutlineVerified className='stock-icon' />Nolikltavā: {item[0].QTY}</p>
                </div>
                :
                <div className='noliktava'>
                  <p><MdOutlineVerified className='stock-icon' />Prece ir noliktavā</p>
                </div>
                :
                <div className='noliktava' style={{ backgroundColor: "#f70d1a" }}>
                  <p>Pieejams 7-14 dienu laikā</p>
                </div>}
              <div className='priceContainer'>
                <input onChange={(e) => setPcsToCart(e.target.value)}
                  type="number"
                  value={pcsToCart}
                  min={item[0].MINORDER}
                  max="30"
                  className='pcsInput'
                />
                <button className='btn-small-red-center' onClick={handleAddToCart}><TbShoppingCartPlus className="addToCartFromProdPIcon" /></button>
              </div>
            </div>

          </div>
          <div className={menu1Dec.includes("kārtridži") ? "teksta-konteiners" : "apraksta-konteiners"}>
            <div className="column" dangerouslySetInnerHTML={{ __html: item[0].TEXT }}></div>
          </div>
          <div>
            {saderigie.length > 0 ?
              <>
                <div className='musu-razotie'>
                  {menu1Dec.includes("Officeshop") ? <h2> <img src='/Icons/iconHand.jpg' alt='Tev ieteiktās preces' /> Iesakām! Izvēlies OfficeShop ražotos kārtridžus un ietaupi!</h2>
                    :
                    <h2>Tev varētu patikt</h2>}

                  <div className="product-page">
                    {saderigie.map((item, index) => (
                      <div key={index} className="product">
                        <ProductCard
                          name={item[0].NAME}
                          price={item[0].PRICE}
                          image={specImage ? `https://www.officeshop.lv${specImage}` : item[0].PICTURE ? item[0].PICTURE.startsWith('http') ? item[0].PICTURE : `/prodImages/Small/${item[0].SKU} (Small)/${item[0].SKU} (Small).${item[0].PICTURE}` : `/prodImages/Small/logo (Small)/logo (Small).png`}
                          quantity={item[0].QTY}
                          discount={item[0].DISCOUNT}
                          minorder={item[0].MINORDER ? item[0].MINORDER : 1}
                          link={
                            item[0].MENU_2 === 'N/A' ? `/categories/${item[0].SKU}` :
                              item[0].MENU_3 === 'N/A' ? `/categories/${encodeURIComponent(item[0].MENU_1).replace(/%20/g, '-')}/${encodeURIComponent(item[0].MENU_2).replace(/%20/g, '-')}/${item[0].SKU}` :
                                `/categories/${encodeURIComponent(item[0].MENU_1).replace(/%20/g, '-')}/${encodeURIComponent(item[0].MENU_2).replace(/%20/g, '-')}/${encodeURIComponent(item[0].MENU_3).replace(/%20/g, '-')}/${item[0].SKU}`
                          }
                          sku={item[0].SKU}
                          fav='no'
                          onDeleteFav={() => { }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </>

              : null}
            {showFreeDelivery ? (
              <div className='FreeDeliveryNotificationMobile'>
                <p>Jūsu pasūtījumam bezmaksas piegāde!
                  <TbTruckDelivery className='delivery' />
                </p>
              </div>
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

export default ProductPage;
