import React from 'react';
import { Helmet } from 'react-helmet';
import '../App.css';

export default function Utilizacija() {
    

    
    
    return (
        <>
            <Helmet>
                <title>Kārtridžu utilizācija | Officeshop.lv </title>
                <meta name="description" content="Printeru kārtridžu utilizācija ir svarīga videi. Mūsu kārtridžu utilizācijas pakalpojumi nodrošina, ka jūsu izlietotie kārtridži tiks iznīcinātas gan jums, gan videi nekaitīgā veidā." />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="Kārtridžu utilizācija, printeru kārtridžu utilizācija, izlietotie kārtridži, kārtridžu pieņemšana, kārtridžu nodošana, kārtridžu utilizācijas pakalpojums, biroja tehnikas utilizācija, kārtridžu pārstrāde" />
                <meta property="og:title" content="Kārtridžu utilizācija | Officeshop.lv" />
                <meta property="og:description" content="OfficeShop.lv piedāvā atbildīgu printeru kārtridžu utilizāciju, aizsargājot vidi." />
                <meta property="og:url" content="https://www.officeshop.lv/utilizacija" />
                <meta property="og:image" content="https://www.officeshop.lv/prodImages/logo.png" />
                <meta property="og:type" content="website" />
                <link rel="canonical" href="https://www.officeshop.lv/utilizacija" />
            </Helmet>
            <div className='recycleCont' >
                <div className='recycleRowCont'>
                    <div className='recycleText'>
                        <h3>Kāpēc ir svarīgi utilizēt printeru kārtridžus?</h3>
                        <p>Printeru kārtridži, kas netiek atbilstoši utilizēti, rada nopietnu draudu videi.</p>
                        <p>Tie satur kaitīgas ķimikālijas un smagos metālus, piemēram, svinu un dzīvsudrabu, kas var noplūst augsnē un ūdens avotos, radot piesārņojumu un kaitējot dzīvniekiem un augiem. </p>
                        <p>Turklāt plastmasas daļas, kas ir kārtridžu sastāvā, var sadalīties simtiem gadu, palielinot atkritumu apjomu.</p>
                    </div>
                    <div className='recyclePictureLeft'>
                        <img src='/images/utilizacija.png' alt='Kārtridžu utilizācija | Officeshop.lv' />
                    </div>
                </div>
                <div className='seperator'></div>
                <div className='recycleMidRow'>
                    <div>
                        <p>Mēs OfficeShop.lv esam atbildīgs un dabai draudzīgs uzņēmums!</p>
                        <p>Saviem klientiem piedāvājam augstas kvalitātes printeru kārtridžus un videi draudzīgu izlietoto kārtridžu utilizācijas pakalpojumu.  </p>
                    </div>
                </div>
                <div className='seperator'></div>
                <div className='recycleRowCont'>
                    <div className='recycleText'>
                        <h3>Aicinām arī Jūs izmantot šo pakalpojumu!</h3>
                        <p>Mūsuprāt tā ir neliela, bet būtiska rīcība, kas palīdz aizsargāt mūsu planētu un padarīt pasauli tīrāku nākamajām paaudzēm.</p>
                        <p>Izsniedzam atbilstošu dokumentāciju (aktu) par utilizācijas pakalpojumu, atbilstoši LR likumdošanai.</p>
                        <p>Kļūsti par mūsu klientu un Tavi izlietotie kārtridži tiks utilizēti atbildīgi un videi draudzīgi!</p>
                    </div>
                    <div className='recyclePictureRight'>
                        <img src='/images/utilizacija2.png' alt='Kārtridžu utilizācija | Officeshop.lv' />
                    </div>
                </div>
            </div>
        </>

    );
}