
import React, { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import ProductCard from '../productCard/ProductCard';
import useFirebase from "../../firebase/usefirebase";




const SpecialOffers = () => {
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [authLoading, setAuthLoading] = useState(true);
    const API_SERVER = process.env.REACT_APP_API_SERVER;
    const { auth, user } = useFirebase();

   
    useEffect(() => {
        if (auth){
            const unsubscribe = onAuthStateChanged(auth, (user) => {
                if (user) {
                    setEmail(user.email);
                    setAuthLoading(false); 
                }else {
                    setAuthLoading(false); 
                  }
            });
            return () => {
                unsubscribe();
            };
        }
        else {
            setAuthLoading(false); 
          }
    }, [auth]);

    useEffect(() => {
        if (authLoading) return; 
        const fetchData = async () => {
            if (email !== "") {
                try {
                    const response = await fetch(`${API_SERVER}/specialOffers?email=${email}`);
                    if (!response.ok) {
                        throw new Error("Failed to fetch user data");
                    }
                    const data = await response.json();
                    setSelectedProducts(data);
                    setIsLoading(false);
                } catch (error) {
                    console.error(error);
                }
            }
        };
        fetchData(); 
    }, [email]);
 
    return (
        
        isLoading ? <div className='product-page'>
        <img src='/images/loading.gif' alt='loading' />
      </div> :
      selectedProducts.length > 0 && (
        <div className="product-page">
          {selectedProducts.map((item, index) => (            
            <div key={index} className="product">
              <ProductCard
                name={item.NAME}
                price={item.PRICE}
                image={item.MENU_1 === "Officeshop ražotie kārtridži" ? '/prodImages/Small/Officeshop Cartridges (Small)/cartridges (Small).jpg':
                    item.MENU_1 === "Kopējošās lentas (ribbon)" ? '/prodImages/Small/Ribbon (Small)/ribbon (Small).jpg':
                    item.PICTURE ? item.PICTURE.startsWith('http') ? item.PICTURE :  `/prodImages/Small/${item.SKU} (Small)/${item.SKU} (Small)${item.EXT}`:
                    `/prodImages/Small/logo (Small)/logo (Small).png`}
                quantity={item.QTY}
                link={
                    item.MENU_3 === 'N/A'
                        ? `/categories/${encodeURIComponent(item.MENU_1).replace(
                              /%20/g,
                              '-'
                          )}/${encodeURIComponent(item.MENU_2).replace(
                              /%20/g,
                              '-'
                          )}/${item.SKU}`
                        : `/categories/${encodeURIComponent(item.MENU_1).replace(
                              /%20/g,
                              '-'
                          )}/${encodeURIComponent(item.MENU_2).replace(
                              /%20/g,
                              '-'
                          )}/${encodeURIComponent(item.MENU_3).replace(
                              /%20/g,
                              '-'
                          )}/${item.SKU}`
                }
                sku={item.SKU}
                fav='yes'
              />
            </div>
          ))}
        </div>
        )
    );
}

export default SpecialOffers;